<template>
    <div class="main blacklist">
        <div class="container">
            <div class="title">
                <button @click="openModal"
                        class="btn float-right new-order-link hidden-xs">
                    Add new item
                </button>
                <h1>
                    Blacklist
                </h1>
            </div>
            <AddBlackListModal ref="blacklistModal"
                            @addBlacklist="addBlacklist"/>
            <BlackListEditModal
                ref="editModal"
                :editData="editBlacklist"
                @update="update"
             />
            <div class="filter-bar-container">
                <div class="filter-bar">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="toggleFilter">
                            <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                                Filter
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <transition name="collapse">
                    <BlacklistFilter
                        ref="blacklistFilter"
                        :blacklist="blacklist"
                        @onFiltersApply="onFiltersApply"
                    />
                </transition>
            </div>
            <div class="table-bar">
                <div>
                    <button class="btn mb-15"
                            v-show="showRemoveBtn"
                            @click.prevent="removeItem">
                        Remove item
                    </button>
                </div>
                <BlacklistTable
                    :blacklist="blacklist"
                    :check="true"
                    @blacklistIsSelected="onBlacklistSelected"
                    @editBlacklist="openEditModal"
                />
                <b-pagination
                        align="center"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :limit="6"
                        @input="getBlacklist(pagData.currentPage)"
                        :total-rows="pagData.maxItems"
                        v-model="pagData.currentPage"
                        :per-page="20"
                        v-if="pagData.maxItems > 20"
                        :disabled="disablePagination"
                />
                <div v-else style="height: 35px;"></div>
                <PaginationCount
                        :pagData="pagData"
                        :activeLanguage="text"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import blacklistTable from '../../components/blacklistView/blacklistTable'
    import blacklistFilter from '../../components/blacklistView/blacklistFilter'
    import SessionStorage from '../../helpers/modules/storage/SessionStorage'
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import AddBlackListModal from '../../components/blacklistView/modals/AddBlackListModal.vue'
    import BlackListEditModal from '../../components/blacklistView/modals/BlackListEditModal.vue'
    import {
        BlackListService,
        ClientBlackListService
    } from '../../services/service-container'
    import Helpers from '../../helpers/index'

    export default {
        name: "Blacklist",
        components: {
            'BlacklistFilter': blacklistFilter,
            'BlacklistTable': blacklistTable,
            AddBlackListModal,
            BlackListEditModal,
            Pagination,
            PaginationCount
        },
        data() {
            return {
                showRemoveBtn: false,
                disablePagination: false,
                blacklist: [],
                pagData: {
                    currentPage: 1,
                    maxItems: 0
                },
                activeFilters: {},
                text: {
                    of: 'of',
                    results: 'results',
                    selected: 'Selected'
                },
                selectedItems: [],
                editBlacklist: {}
            }
        },
        mounted() {
            let blackFilters = SessionStorage.get('blacklistFilterParams')

            if (!!blackFilters) {
                if (Object.keys(blackFilters.filter).length) {
                    this.activeFilters = blackFilters.filter
                    this.$refs.blacklistFilter.fillFilterOptions(this.activeFilters)
                }
                this.getBlacklist(blackFilters.page || 1)
            }
            this.getBlacklist()
        },
        methods: {
            getBlacklist(page = 1) {
                let params = {
                    filter: this.activeFilters,
                    page
                }
                return Promise.all([
                    BlackListService._getBlackListAll(page, params),
                    ClientBlackListService._getAll(page, params)
                ])
                    .then(resp => {
                        let blacklist = resp[0].data
                        let clientblacklist = resp[1].data
                        let data = [
                            ...Helpers.HelperScripts.updateObjects(blacklist.customers_list, 'type', 'blacklist'),
                            ...Helpers.HelperScripts.updateObjects(clientblacklist.clients_list, 'type', 'clients_blacklist')
                        ]
                        this.pagData.currentPage = blacklist.page
                        this.pagData.maxItems = parseFloat(blacklist.total_customers + clientblacklist.total_clients)
                        this.blacklist = data
                        SessionStorage.set('blacklistFilterParams', params)
                    })
                    .catch(err => console.error(err))
            },
            toggleFilter() {
                this.$refs.blacklistFilter.toggle()
            },
            removeItem() {
                let blacklist = this.selectedItems.filter(list => list.type === 'blacklist')
                let clients_blacklist = this.selectedItems.filter(list => list.type === 'clients_blacklist')
                
                let ids_blacklist = blacklist.map(list => list.ids)
                let ids_clients_blacklist = clients_blacklist.map(list => list.ids)

                if (ids_blacklist.length && ids_clients_blacklist.length) {
                    return Promise.all([
                        BlackListService._removeBlackList(ids_blacklist),
                        ClientBlackListService._remove(ids_clients_blacklist)
                    ]).then (resp => this.getBlacklist())
                        .catch(err => console.error(err))
                }

                if (ids_blacklist && !ids_clients_blacklist.length) {
                    return BlackListService._removeBlackList(ids_blacklist)
                        .then(resp => this.getBlacklist())
                        .catch(err => console.error(err))
                }

                if (ids_clients_blacklist && !ids_blacklist.length) {
                    return ClientBlackListService._remove(ids_clients_blacklist)
                        .then(resp => this.getBlacklist())
                        .catch(err => console.error(err))
                }
            },
            onBlacklistSelected(ids) {
                this.showRemoveBtn = Object.values(ids).length
                this.selectedItems = ids
            },
            onFiltersApply(data) {
                this.activeFilters = data
                this.getBlacklist()
            },

            // Add blacklist methods
            openModal() {
                this.$refs.blacklistModal.open()
            },
            addBlacklist(data) {
                let { type } = data
                if (type === 'blacklist') {
                    return BlackListService._saveBlackList(data)
                            .then(resp => {
                                this.$refs.blacklistModal.close()
                            }) 
                            .catch(err => console.error(err))
                } else {
                    data.client_id = JSON.parse(localStorage.getItem('Btoken')).details.id
                    return ClientBlackListService._save(data)
                            .then(resp => {
                                this.$refs.blacklistModal.close()
                            }) 
                            .catch(err => console.error(err))
                }
            },
            
            // Edit blacklist methods
            openEditModal(data) {
                this.editBlacklist = data
                this.$refs.editModal.open()
            },
            update(data) {
                let { blacklist } = data
                if (blacklist === 'blacklist') {
                    return BlackListService._updateBlackList(data)
                            .then(resp => console.log(resp)) 
                            .catch(err => console.error(err))
                } else {
                    data.client_id = JSON.parse(localStorage.getItem('Btoken')).details.id
                    return ClientBlackListService._update(data)
                            .then(resp => console.log(resp)) 
                            .catch(err => console.error(err))
               }
            }
        }
    }
</script>

<style scoped>
    .mb-15 {
        margin-bottom: 15px;
    }
</style>