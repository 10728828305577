<template>
    <b-modal v-model="showModal"
             size="lg"
             hide-footer="true"
             title="Add new blacklist"
             header-class="card-header pause">
        <div class="row">
            <div class="col-md-12" style="display: flex">
                <form class="col-md-12" @submit.prevent="submit">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group col-md-8">
                                <label>First name</label>
                                <input type="text"
                                        v-model="form.first_name"
                                        name="first_name"
                                        placeholder="First name"
                                        class="form-control" />
                            </div>
                            <div class="form-group col-md-8">
                                <label>Last name</label>
                                <input type="text"
                                        v-model="form.last_name"
                                        placeholder="Last name"
                                        class="form-control" />
                            </div>
                            <div class="form-group col-md-8">
                                <label>House number</label>
                                <input type="text"
                                        v-model="form.house_number"
                                        placeholder="House number"
                                        class="form-control" />
                            </div>
                            <div v-if="form.type === 'blacklist'" 
                                 class="form-group col-md-8"
                            >
                                <label>Phone</label>
                                <input type="tel"
                                    v-model="form.phone"
                                    placeholder="Phone"
                                    class="form-control" 
                                />
                            </div>
                            <div v-else class="form-group col-md-8">
                                <label>Client</label>
                                <v-autocomplete
                                    placeholder="Enter Username"
                                    :min-len="2"
                                    v-model="client"
                                    :items="clients"
                                    :get-label="getLabel"
                                    :component-item='itemTemplate'
                                    @update-items="updateClientList"
                                    @item-selected="clientSelected"
                                ></v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group col-md-8">
                                <label>Email</label>
                                <input type="email"
                                    v-model="form.email"
                                    placeholder="Email"
                                    class="form-control" />
                            </div>
                            <div class="form-group col-md-8">
                                <label>Email domain</label>
                                <input type="email"
                                    v-model="form.email_domain"
                                    placeholder="Email domain"
                                    class="form-control" />
                            </div>
                            
                          <div class="form-group col-md-8">
                            <label>Reason</label>
                            <input type="text"
                                   v-model="form.reason"
                                   placeholder="Reason"
                                   class="form-control" />
                          </div>
                          <div class="form-group col-md-8" v-if="form.type !== 'blacklist'">
                                <label>End date</label>
                                <select class="form-control" v-model="form.enddate">
                                    <option value="noenddate">No enddate</option>
                                    <option value="1">1</option>
                                    <option value="3">3</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            
                            
                        </div>
                        <div class="col-md-4">
                            <div class="form-group col-md-8">
                                <label>Post code</label>
                                <input type="text"
                                        v-model="form.postcode"
                                        placeholder="Post code"
                                        class="form-control" />
                            </div>
                            <div class="form-group col-md-8">
                                <label>Postal code range</label>
                                <input type="text"
                                    v-model="form.post_code_range"
                                    placeholder="Post code range"
                                    class="form-control" />
                            </div>
                            <div class="form-group col-md-8">
                                <label>Blacklist type</label>
                                <select class="form-control" v-model=form.type required>
                                    <option value="blacklist">Blacklist</option>
                                    <option value="clients_blacklist">Client blacklist</option>
                                </select>
                            </div>
                        </div>   
                    </div>
                    <div class="row footer">
                        <button type="button" class="btn btn-secondary" @click="close">CANCEL</button>
                        <button type="submit" class="btn">OK</button>   
                    </div> 
                </form>    
            </div>
        </div>
    </b-modal>
</template>

<script>
    import ClientItemTemplate from '../../common/reusable/ClientItemTemplate.vue'

    export default {
        name: "AddBlackListModal",
        data() {
            return {
                showModal: false,
                form: {
                    enddate: 'noenddate',
                    type: 'blacklist'
                },
                clients: [],
				client: null,
				itemTemplate: ClientItemTemplate,
            }
        },
        methods: {
            close () {
                this.showModal = false
            },
            open() {
                this.showModal = true
            },
            submit() {
                if (Object.keys(this.form).length > 2) {
                    this.$emit('addBlacklist', this.form)
                } else {
                    EventBus.$emit('showAlertModal', 'Fill something')
                }
            },
            updateClientList(val) {
		        billinkAxios
			        .post('app/clients/list', { username: val })
			        .then(({data}) => this.clients = data.clients)
			        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
		        this.client = val
                this.form.client_id = val.id
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
        },
        components: {
	        ClientItemTemplate
        },
    }
</script>

<style lang="scss" scoped>
    .footer {
        display: flex;
        justify-content: flex-end;
        button {
            margin-right: 5px;
        }
    }
    input {
        font-size: 14px !important;
    }
</style>