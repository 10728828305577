<template>
    <div class="collapse show" id="filtercollapse" v-if="show">
        <div class="filter-content">
            <form @submit.prevent="applyFilters">
                <div class="form-row">
                    <div class="form-group col-md-8">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input1" class="col-form-label">First name</label>
                                <input v-model="form.first_name" type="text" class="form-control" id="input1">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input2" class="col-form-label">Last name</label>
                                <input v-model="form.last_name" type="text" class="form-control" id="input2">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input3" class="col-form-label">House number</label>
                                <input v-model="form.house_number" type="text" class="form-control" id="input3">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input4" class="col-form-label">Post code</label>
                                <input v-model="form.postcode" type="text" class="form-control" id="input4">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input5" class="col-form-label">Postal code range</label>
                                <input v-model="form.postal_code_range" type="text" class="form-control" id="input5">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input6" class="col-form-label">Email</label>
                                <input v-model="form.email" type="email" class="form-control" id="input6">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input7" class="col-form-label">Email domain</label>
                                <input v-model="form.email_domain" type="text" class="form-control" id="input7">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input8" class="col-form-label">Client</label>
                                <input v-model="form.client" type="text" class="form-control" id="input8">
                            </div>
                          <div class="form-group col-md-4">
                            <label for="input9" class="col-form-label">Reason</label>
                            <input v-model="form.reason" type="text" class="form-control" id="input9">
                          </div>
                        </div>
                        <div class="form-group col-md-12 padd-0">
                            <label class="col-form-label">End date</label>
                            <div id="event_period">
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label class="sr-only">From</label>
                                        <div class="input-group">
                                            <Datepicker
                                                    v-model="end_date_from"
                                                    :calendar-button="true"
                                                    calendar-button-icon="fa fa-calendar"
                                                    input-class="form-control actual_range"
                                                    :placeholder="'From'"
                                                    :format="customDateFormatter"
                                                    @selected="onDateChange($event,'end_date_from')"
                                                    :language="langActive"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group col">
                                        <label class="sr-only">To</label>
                                        <div class="input-group">
                                            <Datepicker
                                                    v-model="end_date_to"
                                                    :calendar-button="true"
                                                    calendar-button-icon="fa fa-calendar"
                                                    class="right"
                                                    input-class="form-control actual_range"
                                                    :placeholder="'To'"
                                                    :format="customDateFormatter"
                                                    @selected="onDateChange($event, 'end_date_to')"
                                                    :language="langActive"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <a class="btn btn-secondary" style="margin-right: 5px;" @click.prevent="clearFilters">Clear</a>
                    <button type="submit" class="btn">Apply Filter</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Datepicker from 'vuejs-datepicker';
    import { en, nl } from 'vuejs-datepicker/dist/locale'
    import  Helpers from '../../helpers/modules/scripts'

    export default {
        name: "blacklistFilter",
        components: {
            Datepicker
        },
        data() {
            return {
                show: false,
                form: {},
                end_date_from: null,
                end_date_to: null,
                nl: nl,
                en: en,
            }
        },
        mounted() {
            this.nl.months = Helpers.uppercaseArr(this.nl.months)
            this.nl.monthsAbbr = Helpers.uppercaseArr(this.nl.monthsAbbr)
            this.nl.days = Helpers.uppercaseArr(this.nl.days)
        },
        computed: {
            langActive () {
                return this.getActiveLanguageName === 'english' ? this.en : this.nl
            }
        },
        methods: {
            clearFilters() {
                this.form = {}
                this.end_date_from = null
                this.end_date_to = null
                this.$emit('onFiltersApply', {})
            },
            customDateFormatter(date) {
                return moment(date).format('DD-MM-YYYY')
            },
            onDateChange(date, type) {
                date ? this.form[type] = date : delete this.form[type]
            },
            toggle() {
                this.show = !this.show
            },
            applyFilters() {
                this.$emit('onFiltersApply', this.form)
            },
            fillFilterOptions(data) {
                this.form = data
                this.show = true
            }
        }
    }
</script>

<style scoped>

</style>