<template>
    <table class="table">
        <thead>
        <tr>
            <th v-if="check" class="table-res">
                <label class="custom-control custom-checkbox">
                    <input
                            type="checkbox"
                            class="custom-control-input"
                            @change="checkAll = !checkAll"
                    >
                    <span class="custom-control-indicator"></span>
                </label>
            </th>
            <th class="text-center">
                <h6>Client</h6>
            </th>
            <th>
               <h6>Firstname</h6>
            </th>
            <th class="table-res">
                <h6>Lastname</h6>
            </th>
            <th class="text-left">
                <h6>Postal code</h6>
            </th>
            <th class="table-res">
                <h6>Postal code area</h6>
            </th>
            <th class="table-res">
                <h6>House number</h6>
            </th>
            <th class="table-res">
                <h6>email</h6>
            </th>
            <th class="table-res">
                <h6>end date</h6>
            </th>
            <th class="table-res">
                <h6>created at</h6>
            </th>
          <th class="table-res">
            <h6>Reason</h6>
          </th>
            <th class="table-res"></th>
        </tr>
        </thead>
        <tfoot v-if="!check" class="tfoot-no-check">
        </tfoot>
        <tbody>
        <tr v-for="item in blacklist" :key="item.id">
            <td v-if="check" class="table-res no-click">
                <label class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" v-model="item.checked" @change="blackListSelected(item)">
                    <span class="custom-control-indicator"></span>
                </label>
            </td>
            <td>{{ item.client_id }}</td>
            <td>{{ item.first_name }}</td>
            <td>{{ item.last_name }}</td>
            <td>{{ item.postcode }}</td>
            <td>{{ item.postal_code_range }}</td>
            <td>{{ item.house_number }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.end_date }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.reason }}</td>
            <td><span @click="$emit('editBlacklist', item)"><i class="fa fa-pencil-square-o icon-edit"></i></span></td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "BlacklistTable",
        props: {
            check: {
                type: Boolean,
                default: false
            },
            blacklist: {
                type: Array
            }
        },
        data() {
            return {
                checkAll: false
            }
        },
        watch: {
            checkAll(val) {
                let ids = []

                this.blacklist.map(list => {
                    list.checked = val
                    if (val) { ids.push(list.id) }
                })

                this.$emit('blacklistIsSelected', ids)
                this.$forceUpdate()
            }
        },
        methods: {
            blackListSelected({checked, id}) {
                let ids = []

                this.blacklist.forEach(list => {
                    if (list.checked) {
                        ids.push({
                            type: list.type,
                            ids: list.id
                        })
                    }
                })
                
                this.$emit('blacklistIsSelected', ids)
            }
        }
    }
</script>

<style scoped lang="scss">
    .fa-stack-2x {
        border-radius: 50%;
        background: #c1a1c7;
    }
    .webshop-th {
        width: 100px;
    }
    .overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        &-inv {
            width: 160px;
        }
    }
    .name-td {
        div {
            width: 160px;
        }
    }
    .icon-edit {
        font-size: 20px;
        margin-top: 10px;
    }
    .icon-edit:hover {
        cursor: pointer;
    }
    table {
        tr {
            th {
                h6 {
                    font-size: 13px;
                    color: #fff;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .table {
            td, th {
                font-size: 10px;
                padding: 2px 6px;
                vertical-align: middle;
            }
            th {
                padding: 6px;
                &.openstaand,
                &.amount {
                    width: 70px;
                }
            }
            .overflow {
                width: 70px;
            }
            .tfoot-no-check td {
                text-align: center;
                &>* {
                    padding: 5px;
                    font-size: 11px;
                    margin: 0 20px;
                }
            }
        }
    }
    @media (max-width:1240px) {
        th i {
            display: none;
        }
        .cl .overflow {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    @media (max-width:480px) {
        .table {
            .cl .overflow {
                width: 80px;
            }
            td:last-child,
            .openstaand {
                border-right: 1px solid #003d5b !important;
            }
        }
    }
</style>