<template>
    <b-modal v-model="showModal"
             @ok="submit"
             @cancel="close"
             size="lg"
             title="Edit blacklist"
             header-class="card-header pause">
        <div class="row">
            <div class="col-md-12" style="display: flex">
                    <div class="col-md-6">
                         <div class="form-group col-md-8">
                             <label>First name</label>
                             <input type="text"
                                    v-model="editData.first_name"
                                    placeholder="First name"
                                    class="form-control" />
                         </div>
                         <div class="form-group col-md-8">
                             <label>Last name</label>
                             <input type="text"
                                    v-model="editData.last_name"
                                    placeholder="Last name"
                                    class="form-control" />
                         </div>
                         <div class="form-group col-md-8">
                             <label>House number</label>
                             <input type="text"
                                    v-model="editData.house_number"
                                    placeholder="House number"
                                    class="form-control" />
                         </div>
                         <div class="form-group col-md-8">
                             <label>Post code</label>
                             <input type="text"
                                    v-model="editData.postcode"
                                    placeholder="Post code"
                                    class="form-control" />
                         </div>
                         <div class="form-group col-md-8">
                            <label>Postal code range</label>
                            <input type="text"
                                   v-model="editData.post_code_range"
                                   placeholder="Post code range"
                                   class="form-control" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-md-8">
                            <label>Email</label>
                            <input type="email"
                                   v-model="editData.email"
                                   placeholder="Email"
                                   class="form-control" />
                        </div>
                        <div class="form-group col-md-8">
                            <label>Email domain</label>
                            <input type="email"
                                   v-model="editData.email_domain"
                                   placeholder="Email domain"
                                   class="form-control" />
                        </div>
                      <div class="form-group col-md-8">
                        <label>Reason</label>
                        <input type="text"
                               v-model="editData.reason"
                               placeholder="Reason"
                               class="form-control" />
                      </div>
                        <div class="form-group col-md-8" v-show="editData.type !== 'blacklist'">
                            <label></label>
                            <select class="form-control" required>
                                <option value="noenddate">No enddate</option>
                                <option value="1">1</option>
                                <option value="3">3</option>
                                <option value="6">6</option>
                            </select>
                        </div>
                        <div v-show="editData.type === 'blacklist'" 
                             class="form-group col-md-8"
                             >
                            <label>Phone</label>
                            <input type="tel"
                                   v-model="editData.phone"
                                   placeholder="Phone"
                                   class="form-control" />
                        </div>
                        <div class="form-group col-md-8">
                            <label></label>
                            <select class="form-control" v-model=editData.type>
                                <option value="blacklist">Blacklist</option>
                                <option value="clients_blacklist">Client blacklist</option>
                            </select>
                        </div>
                    </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'BlackListEditModal',
    props: {
         editData: {
             type: Object
         }   
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        open() {
            this.showModal = true
        },
        close() {
            this.showModal = false
        },
        submit() {
            this.$emit('update', this.editData)
        }
    }
}
</script>

